body {
    background-color: #f0f0f0;
}

.screen {
    border: 2px solid #e0e0e0;
    border-radius: 5px;
    padding: 10px 30px 10px 30px;;
    background-color: #ffffff;
    margin-top: 50px;
}

.buttonbox {
    text-align: right;
    margin-bottom: 10px;
    margin-top: 20px;
}

label {
    margin-top: 10px;
}


.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {  
  50% { opacity: 0; }
}
